<template>
  <page-layout
    :title="$t('404_page.title')"
    class="not-found-page"
  >
    <h1 v-html="$t('404_page.title')"></h1>
    <p v-html="$t('404_page.top_content')"></p>

    <svg width="80" height="96" viewBox="0 0 80 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69.3218 61.601C76.157 30.0333 44.8016 8.15426 44.8016 8.15426L40.0557 12.6816L25.8179 0C25.8179 0 -13.8374 23.9172 5.14475 74.2842C5.14475 74.2842 69.0552 61.601 69.3218 61.601Z" fill="#0F89EA"/>
      <path d="M20.5265 38.7018C16.0797 38.7018 12.4404 35.0626 12.4404 30.6158C12.4404 26.1705 16.0797 22.5312 20.5265 22.5312C24.9733 22.5312 28.611 26.1705 28.611 30.6158C28.611 35.0626 24.9733 38.7018 20.5265 38.7018Z" fill="#F7F8F9"/>
      <path d="M25.1436 30.6179C25.1436 33.1691 23.0759 35.2383 20.5247 35.2383C17.9735 35.2383 15.9028 33.1691 15.9028 30.6179C15.9028 28.0667 17.9735 25.9991 20.5247 25.9991C23.0759 25.9991 25.1436 28.0667 25.1436 30.6179Z" fill="#0F89EA"/>
      <path d="M20.833 28.4614C20.833 29.8222 19.7294 30.9258 18.3686 30.9258C17.0077 30.9258 15.9042 29.8222 15.9042 28.4614C15.9042 27.1005 17.0077 25.997 18.3686 25.997C19.7294 25.997 20.833 27.1005 20.833 28.4614Z" fill="#F7F8F9"/>
      <path d="M23.6836 31.8505C23.6836 32.5743 23.0962 33.1602 22.3739 33.1602C21.6501 33.1602 21.0657 32.5743 21.0657 31.8505C21.0657 31.1282 21.6501 30.5423 22.3739 30.5423C23.0962 30.5423 23.6836 31.1282 23.6836 31.8505Z" fill="#F7F8F9"/>
      <path d="M46.0894 38.7018C41.6442 38.7018 38.0049 35.0626 38.0049 30.6158C38.0049 26.1705 41.6442 22.5312 46.0894 22.5312C50.5362 22.5312 54.1755 26.1705 54.1755 30.6158C54.1755 35.0626 50.5362 38.7018 46.0894 38.7018Z" fill="#F7F8F9"/>
      <path d="M50.71 30.6179C50.71 33.1691 48.6408 35.2383 46.0896 35.2383C43.5399 35.2383 41.4692 33.1691 41.4692 30.6179C41.4692 28.0667 43.5399 25.9991 46.0896 25.9991C48.6408 25.9991 50.71 28.0667 50.71 30.6179Z" fill="#0F89EA"/>
      <path d="M46.3984 28.4614C46.3984 29.8222 45.2949 30.9258 43.934 30.9258C42.5732 30.9258 41.4696 29.8222 41.4696 28.4614C41.4696 27.1005 42.5732 25.997 43.934 25.997C45.2949 25.997 46.3984 27.1005 46.3984 28.4614Z" fill="#F7F8F9"/>
      <path d="M49.2471 31.8505C49.2471 32.5743 48.6612 33.1602 47.9374 33.1602C47.2151 33.1602 46.6292 32.5743 46.6292 31.8505C46.6292 31.1282 47.2151 30.5407 47.9374 30.5407C48.6612 30.5407 49.2471 31.1282 49.2471 31.8505Z" fill="#F7F8F9"/>
      <path d="M33.3089 46.3842C41.3268 46.3842 48.4038 50.0684 51.3394 55.7691C51.6603 56.3922 51.4154 57.1563 50.7908 57.4772C50.1723 57.7949 49.4036 57.5531 49.0843 56.9301C46.6183 52.1392 40.2775 48.9215 33.3089 48.9215C26.3388 48.9215 19.9995 52.1407 17.532 56.9301C17.2127 57.5547 16.4471 57.7949 15.8255 57.4772C15.2009 57.1563 14.956 56.3922 15.2769 55.7691C18.2125 50.0684 25.2895 46.3842 33.3089 46.3842Z" fill="#F7F8F9"/>
      <path d="M48.28 75.461C48.7217 77.7533 50.9598 79.2676 53.2537 78.8243L56.8775 78.1253L46.761 90.4536C45.6637 91.9865 46.0201 94.1393 47.553 95.2367C49.0859 96.3325 51.2373 95.9745 52.3346 94.4431C52.3346 94.4431 57.6788 88.744 58.0074 88.3239C58.336 87.9008 58.5824 88.4712 58.5824 88.4712C58.133 90.3916 58.8723 91.8764 60.6516 92.8777L61.2096 93.1908C62.5875 93.975 64.7512 94.2199 65.9834 92.9707C65.9834 92.9707 67.1102 94.8756 68.7609 95.5854L68.9283 95.6583C70.5775 96.3682 72.4653 95.9931 73.1752 94.3455L76.7308 86.5446C79.8601 79.9852 80.9916 72.4711 73.4263 68.5622C70.8565 67.2339 68.857 66.727 67.2048 66.8045C66.8545 66.7983 66.5151 66.8123 66.1989 66.8681L51.6433 70.4872C49.3494 70.9305 47.8367 73.1686 48.28 75.461Z" fill="#0F89EA"/>
    </svg>

    <p v-html="$t('404_page.bottom_content')"></p>
  </page-layout>
</template>

<script>

import PageLayout from '@/layouts/PageLayout'

export default {
  name: 'NotFound',
  components: { PageLayout }
}
</script>
